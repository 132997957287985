

































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import PublisherStore from "@/store/modules/publisher-store";
import Category from "@/models/Category";
import { Publisher } from "@/models/Publisher";

@Component({
  components: {}
})
export default class Publishers extends Vue {
  @Prop({ required: false }) readonly publisherId!: string;
  private publisherStore: PublisherStore = getModule(PublisherStore);

  private category = new Category();

  private showPublisherSearchDetails = true;
  private publishersFilter = "";
  private publishersLoading = true;

  public constructor() {
    super();
    this.loadPublishers(this.publisherStore);
  }

  private loadPublishers(publisherStore: PublisherStore) {
    publisherStore.loadPublishers().then(() => {
      this.computeSelectedPublisherId();
      this.publishersLoading = false;
    });
  }

  private loadPublisher() {
    console.log("loadPublisher", this.publisherId);
    this.publisherStore.publishers.forEach(item => {
      if (item.id == this.publisherId) {
        this.publisherStore.setSelectedPublisher(item);
      }
    });
  }

  private publishersForCategory(category: string): Publisher[] {
    return this.publisherStore.categorizedPublishersFiltered.get(category.toLowerCase()) || [];
  }

  private isPublisherCategoryEmpty(category: string): boolean {
    const publishers = this.publisherStore.categorizedPublishersFiltered.get(category.toLowerCase());
    return publishers == undefined || publishers.length <= 0;
  }

  @Watch("publishersFilter")
  filterPublishers(): void {
    this.publisherStore.filterCategorizePublishers(this.publishersFilter);
  }

  @Watch("publisherId")
  computeSelectedPublisherId(): void {
    console.log("PublisherId", this.publisherId);
    if (this.publisherId == null) {
      this.showPublisherSearchDetails = true;
      this.publisherStore.setSelectedPublisher(new Publisher());
    } else {
      this.showPublisherSearchDetails = false;
      this.loadPublisher();
    }
  }

  private isPublisherSelected(): boolean {
    console.log("isPublisherSelected", this.publisherId, this.publisherStore.selectedPublisher);
    return this.publisherId != null && this.publisherStore.selectedPublisher != null;
  }

  private deselectPublisher(): void {
    this.$router.push({ name: "publishers" });
  }
}
