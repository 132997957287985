import { store } from "@/store";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { cloneDeep } from "lodash";
import { Nullable, Publisher } from "@/models/Publisher";
import { plainToClass } from "class-transformer";

import axios from "axios";
import Category from "@/models/Category";

declare const SERVICE_BASE_URL: string;

@Module({
  name: "publisher",
  namespaced: true,
  stateFactory: true,
  dynamic: true,
  store
})
export default class PublisherStore extends VuexModule {
  public publishers: Array<Publisher> = [];
  public categorizedPublishers: Map<string, Array<Publisher>> = new Map();

  public categorizedPublishersFiltered: Map<string, Array<Publisher>> = new Map();
  public selectedPublisher: Nullable<Publisher> = null;

  private category = new Category();

  @Mutation
  public setSelectedPublisher(publisher: Nullable<Publisher>): void {
    this.selectedPublisher = plainToClass(Publisher, publisher);
  }

  @Mutation
  public setPublishers(publishers: Publisher[]): void {
    this.publishers = plainToClass(Publisher, publishers);
  }

  @Mutation
  private categorizePublishers(publishers: Publisher[]): void {
    this.categorizedPublishers = new Map();
    publishers.forEach(item => {
      let category = item.name.substring(0, 1).toLowerCase();
      if (this.category.categoryWithoutOther.indexOf(category) == -1) {
        category = this.category.categoryOther;
      }
      let publisherList = this.categorizedPublishers.get(category);
      if (publisherList == null) {
        publisherList = [];
      }
      publisherList.push(item);
      this.categorizedPublishers.set(category, publisherList);
    });
    this.categorizedPublishersFiltered = cloneDeep(this.categorizedPublishers);
  }

  @Mutation
  public filterCategorizePublishers(filter: string): void {
    if (filter == null) {
      filter = "";
    }
    this.categorizedPublishers.forEach((publishers, category) => {
      this.categorizedPublishersFiltered.set(
        category,
        publishers.filter(element => {
          return element.name.toLowerCase().search(filter.toLowerCase()) != -1;
        })
      );
    });
  }

  @Action
  public async loadPublishers(): Promise<void> {
    return await axios
      .get(SERVICE_BASE_URL + "/publisher/approved/")
      .then(res => {
        // console.log("res!", res.data);
        this.context.commit("setPublishers", res.data);
        this.context.commit("categorizePublishers", res.data);
      })
      .catch(error => console.error("load publishers error!", error));
  }
}
