import { Type } from "class-transformer";

export type Nullable<T> = T | null;

export class PublisherListItem {
  id!: string;
  name!: string;
  image!: string; //types.optional(types.string, "https://placehold.it/50x50")
}

export class PublisherList {
  @Type(() => PublisherListItem)
  items!: PublisherListItem[];
}

export class Publisher {
  id!: string;
  creatorId!: string;
  name!: string;
  // version: 0
  workflowState!: string;
}
